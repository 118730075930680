<template>
  <Monitor :datas="[4, 'viewDoseMonitor']" />
</template>
<script>
import Monitor from '@/components/threshold/Monitor.vue'
export default {
  components: {
    Monitor,
  },
  data() {
    return {}
  },
}
</script>
